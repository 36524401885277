.menuMovil {
    display: initial;
    position: absolute;
    background-color: #eee8ec;
    width: 100%;
    height: 400%;
    z-index: 1;
}
.buttonMenu {
    position: absolute;
    z-index: 2;
    right: 1rem;
}
.iconInstagram {
    width: 25px;
}
.wrapper_iconInstagram {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
