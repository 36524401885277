.logo {
    width: 150px;
}

.logo-link {
    width: 213px;
}

.menu {
    display: none;
    position: absolute;
    background-color: #eee8ec;
}
.header {
    display: flex;
    justify-content: space-between;
}
.buttonMenu {
    width: 2rem;
    margin-top: 1.1rem;
    margin-right: 1rem;
    cursor: pointer;
}

.banner {
    background-color: rgba(0, 0, 0, 0.928);
    color: #ededed;
    text-align: center;
    margin-top: -10px;
    width: 100%;
    font-size: 10px;
    height: 20px;
    letter-spacing: 2px;
}

.text-banner {
    padding-top: 4px;
}

@media (max-width: 600px) {
    .banner {
        display: none;
    }
}

@media (max-width: 1010px) {
    .logo {
        width: 150px;
    }
}

@media (min-width: 1010px) {
    .buttonMenu {
        display: none;
    }
    .header {
        display: flex;
        justify-content: space-between;
        height: 85px;
        width: 100%;
        background-color: #eee8ec;
    }

    .menu {
        position: relative;
        background-color: #eee8ec;

        text-decoration: none;
        display: flex;
    }

    .logo {
        padding-left: 7%;
        width: 40%;
    }
}
