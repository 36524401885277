.containerAboutMe {
    margin-top: 1rem;
    width: auto;
    margin: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
    color: #000000;
    line-height: 21px;
    word-spacing: 1px;
}
.title__aboutMe {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 2rem;
    margin-left: 13%;
    color: black;
}
.imageBowl {
    width: 30%;
    margin-left: 40%;
}
.paragraph__first {
    width: 100%;
    font-size: small;
}
.imageWorking {
    width: 45%;
    margin-left: 33%;
}
.imageStretchingDestop {
    display: none;
}

.imageStretchingMobile {
    width: 45%;
    margin-left: 33%;
    display: initial;
}
.imageHipos {
    width: 40%;
    margin-left: 35%;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.paragraph__second {
    display: none;
}
.paragraph__fourth {
    font-size: small;
}

.paragraph__third {
    font-size: small;
}

@media (min-width: 600px) {
    .imageStretchingMobile {
        display: none;
    }
    .paragraph__third {
        font-size: initial;
    }
    .imageBowl {
        width: 25%;
        margin-left: initial;
    }
    .wrapperAbout {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 4rem;
    }
    .containerAboutMe {
        margin-top: 2rem;
        width: 80%;
        margin-left: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        color: #000000;
        line-height: 21px;
        word-spacing: 1px;
    }
    .paragraph__first {
        margin-left: 6rem;
        margin-top: -4%;
        width: 40%;
        font-size: initial;
    }
    .imageWorking {
        width: 25%;
        margin-top: -6%;
        margin-left: 0;
    }

    .paragraph__second {
        display: initial;
        width: 55%;
        margin-right: 6rem;
    }

    .imageStretchingDestop {
        width: 25%;
        margin-right: 6rem;
        display: initial;
    }

    .imageHipos {
        width: 25%;
        margin-left: 6rem;
        margin-top: -10%;
        margin-bottom: initial;
    }

    .paragraph__fourth {
        margin-right: 10%;
        font-size: initial;
    }

    .title__aboutMe {
        font-size: 2em;
        margin-bottom: 3rem;
        color: black;
        margin-left: initial;
    }
}
