.containerContactPage {
    text-align: center;
}

.wrapperContact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wrapperContact__info {
    text-align: start;
    font-weight: bold;
    font-size: small;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: auto;
}
.imageContact {
    height: 200px;
    margin-bottom: 1rem;
}
.title__contact {
    font-size: x-large;
}
.paragraph__contact {
    text-align: justify;
    font-size: small;
    margin-left: 2rem;
    margin-right: 2rem;
}
.iconContact {
    width: 35px;
    height: 35px;
    margin-right: 1rem;
    margin-left: -1px;
    margin-top: 5px;
}

.iconWhatssap {
    width: 60px;
    height: 52px;
    margin-left: -13px;
}

.iconEmail {
    width: 30px;
    height: 30px;
    border-radius: 8px;
    margin-right: 1rem;
    margin-left: 1px;
    margin-top: 0.5rem;
}
.containerContactIcon {
    margin-left: 2rem;
    display: flex;
    color: black;
}

a:link,
a:visited,
a:active {
    text-decoration: none;
}

.wrapperImage {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    margin: 2rem;
}

@media (min-width: 600px) {
    .wrapper__container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 9rem;
        margin-top: 3rem;
        margin-left: 8rem;
        margin-right: 8rem;
    }

    .wrapperImage {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 0 3rem;
    }

    .containerContactPage {
        text-align: center;
    }
    .imageContact {
        height: 300px;
    }
    .title__contact {
        font-size: 2rem;
    }
    .wrapperContact {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 3rem;
        gap: 1rem;
        /* padding-right: 6rem; */
    }

    .wrapperContact__info {
        text-align: start;
        font-weight: bold;
        font-size: initial;
        margin-bottom: initial;
        margin-top: initial;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: auto;
    }

    .paragraph__contact {
        width: auto;
        text-align: start;
        text-align: justify;
        font-size: initial;
        margin-left: initial;
        margin-right: initial;
    }

    .containerContactIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: initial;
    }

    .paragraph {
        margin: 0;
    }

    .paragraph:hover {
        color: rgba(0, 0, 0, 0.31);
    }
}
