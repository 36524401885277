footer {
    background-color: #7a7a7ab9;
    width: 101.6%;
    margin-bottom: -3%;
    margin-left: -1%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
}
.footer__logo {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    width: 35px;

    height: 55px;
}
.instagram {
    display: none;
}
.warapperInstagram {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
}

.copyright {
    margin-right: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    direction: rtl;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    gap: 0.5rem;
}
.copyright__ap {
    margin-top: 0;
    margin-bottom: 0;
    font-size: small;
}

.legal {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    gap: 4px;
    font-size: 8px;
}

.legal__link {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.legal__link:hover {
    color: black !important;
}

@media (min-width: 600px) {
    footer {
        background-color: #7a7a7ab9;
        width: 100%;
        margin-bottom: -3%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: white;
        margin-left: 0rem;
    }
    .footer__logo {
        margin-left: 4rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 40px;
    }

    .legal {
        margin-top: 0;
        margin-bottom: 0;
        font-size: small;
        display: flex;
        flex-direction: row;
        gap: 4px;
    }

    .copyright__cookies {
        font-size: 3.5mm;
        margin-top: 0;
        margin-bottom: 0;
        font-style: italic;
        cursor: pointer;
    }
    .copyright__cookies:hover {
        color: rgb(62, 62, 62);
        font-weight: bold;
    }

    .instagram {
        display: inline;
        margin-left: 14rem;
    }

    .instagram:hover {
        color: rgb(62, 62, 62);
        font-weight: bold;
    }

    .warapperInstagram {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: white;
    }
    .wrapperLogoInstagram {
        display: flex;
    }
}
